/**
 * @namespace {Elememt} Elememt
 * @example import "frontools/polyfills/Element";
 */


import "./Math";

/** @type {number} */
var scrollIntoViewportAnimationId = 0;

/**
 * @todo SPLIT into: contained/spanning
 */
Object.defineProperty( Element.prototype, "verticalState", {
	get: function () {
		var self = this,
			properties = {
				margins: [ 0, 0 ],
				current: window
			};

		/**
		 * Get verticalState properties delimited with margins
		 * @param {string} stateName - verticalState property name
		 * @param {number} marginTop - viewport top desired margin
		 * @param {number} marginBottom - viewport bottom desired margin
		 * @returns {*} - verticalState result
		 */
		properties.getBoundedState = function ( stateName, [ marginTop = 0, marginBottom = marginTop ] = [], container = window ) {
			this.margins = [ parseInt( marginTop, 10 ), parseInt( marginBottom, 10 ) ];
			this.current = container;

			return properties[ stateName ];
		};

		Object.defineProperties( properties, {
			"top": {

				/**
				 * Return the Y top position of the element in the page.
				 * @returns {number} - top position
				 */
				get: function () {
					var element = self,
						top = element.offsetTop;

					while ( ( element = element.offsetParent ) !== null && element !== properties.current ) {
						top += element.offsetTop;
					}

					return parseInt( top, 10 );
				}
			},
			"topProgress": {

				/**
				 * Actual position progress on the page from the top
				 * @returns {number} - percentage from top
				 */
				get: function () {
					const wTop = properties.current == window ? window.pageYOffset : properties.current.scrollTop,
						wHeight = properties.current == window ? window.innerHeight : properties.current.offsetHeight;

					return 1 - ( ( properties.top - ( wTop + properties.margins[ 0 ] ) ) /
						( wHeight - properties.margins[ 1 ] ) );
				}
			},
			"bottomProgress": {

				/**
				 * Actual position progress on the page from the bottom
				 * @returns {number} - percentage from bottom
				 */
				get: function () {
					const wTop = properties.current == window ? window.pageYOffset : properties.current.scrollTop,
						wHeight = properties.current == window ? window.innerHeight : properties.current.offsetHeight;

					return 1 - ( ( properties.top + self.offsetHeight - ( wTop +
						properties.margins[ 0 ] ) ) / ( wHeight - properties.margins[ 1 ] ) );
				}
			},
			"ahead": {

				/**
				 * Detect if the element is below the fold
				 * @returns {boolean} - true if below the fold
				 */
				get: function () {
					const wTop = properties.current == window ? window.pageYOffset : properties.current.scrollTop,
						wHeight = properties.current == window ? window.innerHeight : properties.current.offsetHeight;

					return ( wTop - properties.margins[ 1 ] + wHeight ) < properties.top;
				}
			},
			"entering": {

				/**
				 * Detect if the element is entering the viewport.
				 * @returns {boolean} - true if on pixel is visible from bottom
				 */
				get: function () {
					const top = properties.top,
						wHeight = ( properties.current == window ? window.innerHeight : properties.current.offsetHeight ) - properties.margins[ 1 ],
						wTop = properties.current == window ? window.pageYOffset : properties.current.scrollTop,
						height = Math.min( self.offsetHeight, wHeight );

					return ( ( wTop + wHeight ) > top ) &&
						( ( wTop + wHeight ) < ( top + height ) );
				}
			},
			"contained": {

				/**
				 * Detect if the element is totally visible in the viewport.
				 * @returns {boolean} - true if the entire element is visible
				 */
				get: function () {
					const elTop = properties.top,
						elHeight = self.offsetHeight,
						wTop = properties.current == window ? window.pageYOffset : properties.current.scrollTop,
						wHeight = properties.current == window ? window.innerHeight : properties.current.offsetHeight,
						diff = elHeight > wHeight ? elHeight - wHeight : 0;

					return ( ( wTop + wHeight - properties.margins[ 1 ] + diff ) > ( elTop + elHeight ) ) &&
						( ( wTop + properties.margins[ 0 ] - diff ) < elTop );
				}
			},
			"exiting": {

				/**
				 * Detect if the element is existing the viewport.
				 * @returns {boolean} - true if one pixel is hidden on top
				 */
				get: function () {
					const elTop = properties.top,
						elHeight = self.offsetHeight,
						wHeight = properties.current == window ? window.innerHeight : properties.current.offsetHeight,
						top = Math.max( elTop - properties.margins[ 0 ], elTop + elHeight - wHeight ),
						wTop = properties.current == window ? window.pageYOffset : properties.current.scrollTop;

					return ( ( wTop > top ) && ( wTop + properties.margins[ 0 ] < elTop + elHeight ) );
				}
			},
			"behind": {

				/**
				 * Detect if the element is above the fold.
				 * @returns {boolean} - true if above the viewport
				 */
				get: function () {
					const wTop = properties.current == window ? window.pageYOffset : properties.current.scrollTop;

					return wTop + properties.margins[ 0 ] > ( properties.top + self.offsetHeight );
				}
			}
		} );

		return properties;
	}
} );


/**
 * like element.scrollIntoView({block: "top", behavior: "smooth"});
 * @param {Object<Number>} [speed = 35] - Average pixel per frame (~ 60fps)
 * @param {Object<Number>} [marginTop = 0] - top margin decal in pixel
 * @param {Object<Function>} [callback = null] - callback function
 * @param {Object<HTMLElement>} [win = window] - element to scroll
 * @returns {void}
 */
Element.prototype.scrollIntoViewport = function scrollIntoViewport
	( { speed = 35, marginTop = 0, callback = null, win = window } = {} ) {
	var start, offset, toGo, goto, delta, pageHeight, windowHeight, next, change;

	// Prevent win declaration to an element without Y scroll overflow
	if ( win !== window && win.clientHeight === win.scrollHeight ) {
		win = window;
	}

	start = Date.now();
	offset = win === window ? window.pageYOffset : win.scrollTop; // or pageYOffset=scrollY
	toGo = offset;
	goto = this.verticalState.top - marginTop - ( win === window ? 0 : win.verticalState.top );
	delta = goto - offset;
	pageHeight = win === window ? Math.max(
		document.body.scrollHeight,
		document.documentElement.scrollHeight )
		: win.scrollHeight;
	windowHeight = win === window ? window.innerHeight : win.clientHeight;
	next = pageHeight - goto;
	change = delta;

	window.cancelAnimationFrame( scrollIntoViewportAnimationId );

	if ( next < windowHeight ) {
		delta = delta - ( windowHeight - next );
		change = delta;
	}

	/**
	 * Scroll to the element
	 * @returns {void}
	 */
	function step () {
		if ( toGo !== ( win === window ? window.pageYOffset : win.scrollTop ) ) {
			return;
		}

		change -= Math.easeOutCubic( Date.now() - start, 0, change, Math.abs( delta ) * speed );
		toGo = Math.floor( offset + delta - change + 1 );

		if ( win === window ) { window.scrollTo( 0, toGo ); } // or scroll()
		win.scrollTop = toGo;

		if ( Math.abs( Math.abs( delta ) - Math.abs( change ) ) < Math.abs( delta ) - 1 ) {
			scrollIntoViewportAnimationId = window.requestAnimationFrame( step );
		}
		else {
			if ( typeof callback === "function" ) { callback(); }
			if ( typeof callback === "string" && typeof window[ callback ] === "function" ) { window[ callback ](); }
		}
	}

	if ( change === 0 ) {
		if ( typeof callback === "function" ) { callback(); }
		if ( typeof callback === "string" && typeof window[ callback ] === "function" ) { window[ callback ](); }
	}
	else {
		scrollIntoViewportAnimationId = window.requestAnimationFrame( step );
	}
};


/**
 * Remove all children from the current Node
 * @returns {void}
 */
HTMLElement.prototype.empty = function empty () {
	while ( this.hasChildNodes() ) {
		this.removeChild( this.lastChild );
	}
};
