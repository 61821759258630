/**
 * Anchor-like scroll to helper.
 * @module events/scroll
 * @see module:polyfills/element
 * @example import set_scroll from "frontools/events/scroll";
 */

import "../polyfills/Element";

/**
 * Set Event listener to all links that have a scroll data attribute.
 * @param {HTMLElement} [container=document.body] - Search inside this container
 * @returns {void}
 * @example set_scroll( document.body ); // <a data-scroll='elementID;{"marginTop":150}'>click here</a>
 */
export default function ( container = document.body ) {
	[ ...container.querySelectorAll( "*[data-scroll]" ) ].forEach( function ( element ) {
		element.addEventListener( "click", function ( event ) {
			const data = event.currentTarget.getAttribute( "data-scroll" ).split( ";" ),
				options = data[ 1 ] ? JSON.parse( data[ 1 ] ) : {};

			event.preventDefault();
			document.querySelector( data[ 0 ] ).scrollIntoViewport( options );
		} );
	} );
}
