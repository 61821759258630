/**
 * @namespace {Math} Math
 * @example import "frontools/polyfills/Math";
 * @todo Add the possibility to define a cubic bezier curve
 */


/**
 * Easing functions: easeInCubic
 * @name Math.easeInCubic
 * @param {Number} time - Current time / current frame
 * @param {Number} begin - Start value
 * @param {Number} change - Change in value
 * @param {Number} duration - Duration / number of frames
 * @returns {Number} - Actual state depending on the time
 * @example change += Math.easeInCubic( Date.now() - start, 0, change, 1000 );
 */
Math.easeInCubic = function easeInCubic ( time, begin, change, duration ) {
	return change * ( time = time / duration ) * time * time + begin;
};

/**
 * Easing functions: easeOutCubic
 * @name Math.easeOutCubic
 * @param {Number} time - Current time / current frame
 * @param {Number} begin - Start value
 * @param {Number} change - Change in value
 * @param {Number} duration - Duration / number of frames
 * @returns {Number} - Actual state depending on the time
 * @example change += Math.easeOutCubic( Date.now() - start, 0, change, 1000 );
 */
Math.easeOutCubic = function easeOutCubic ( time, begin, change, duration ) {
	return change * ( ( time = time / duration - 1 ) * time * time + 1 ) + begin;
};

/**
 * Easing functions: easeInOutCubic
 * @name Math.easeInOutCubic
 * @param {Number} time - Current time / current frame
 * @param {Number} begin - Start value
 * @param {Number} change - Change in value
 * @param {Number} duration - Duration / number of frames
 * @returns {Number} - Actual state depending on the time
 * @example change += Math.easeInOutCubic( Date.now() - start, 0, change, 1000 );
 */
Math.easeInOutCubic = function easeInOutCubic ( time, begin, change, duration ) {
	time /= duration / 2;
	if ( time < 1 ) { return change / 2 * time * time * time + begin; }
	time -= 2;

	return change / 2 * ( time * time * time + 2 ) + begin;
};


/**
 * Random a number between min and max
 * @name Math.randomArbitrary
 * @param {Number} min - Minimal value
 * @param {Number} max - Maximal value
 * @returns {Number} - float between min and max
 * @example Math.randomArbitrary( 10, 40 ); // between 10 and 40
 */
Math.randomArbitrary = function randomArbitrary ( min = 0, max = 1 ) {
	return Math.random() * ( max - min ) + min;
};


/**
 * Returns preceding and following values
 * @name Math.getNeighbors
 * @param {Number} current - Current position
 * @param {Number} min - Minimal value
 * @param {Number} max - Maximal value
 * @returns {{previous: Number, next: Number}} - Next and previous value
 * @example var n = Math.getNeighbors( 3, 0, 3 ); // n.previous = 2; n.next = 0;
 */
Math.getNeighbors = function getNeighbors ( current, min, max ) {
	return {
		previous: current - 1 < min ? max : current - 1,
		next: current + 1 > max ? min : current + 1
	};
};


/**
 * Normalization
 * @param {number} v - Initial value
 * @param {number} vmin - Initial minimum
 * @param {number} vmax - Initial maximun
 * @param {number} tmin - Transformed minimun
 * @param {number} tmax - Transformed maximum
 * @returns {number} - Transformed value
 * @example Math.normalize( 0.5, -1, 1, 10, 20 ); // 17.5
*/
Math.normalize = function ( v, vmin, vmax, tmin, tmax ) {
	var nv = Math.max( Math.min( v, vmax ), vmin ),
		dv = vmax - vmin,
		pc = ( nv - vmin ) / dv,
		dt = tmax - tmin,
		tv = tmin + ( pc * dt );

	return tv;
};


/**
 * Calculate the average value from the last values
 * @param {number[]} points - List of points
 * @param {number} [max = 20] - Limit
 * @returns {{current: number, points: number[]}} - Current value and sliced array
 * @example Math.average( [ 2, 4, 6 ], 2 ); // { current: 5, points: [ 4, 6 ] }
 */
Math.average = function ( points, max = 20 ) {
	var l = points.length,
		sum;

	points = points.slice( Math.max( 0, l - max ), l );
	sum = points.reduce( ( a, b ) => a + b, 0 );

	return {
		current: sum / Math.min( l, max ),
		points
	};
};
