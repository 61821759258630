/* eslint no-console: "off" */

/**
 * ======= HoW tO wrItE CoDE =======
 * | variable       : likeThis     |
 * | private var    : _likeThis    |
 * | constant       : LIKE_THIS    |
 * | class          : LikeThis     |
 * | method         : like_this    |
 * | private method : _like_this   |
 * | callback       : like_this_cb |
 * | event callback : like_this_ev |
 * =================================
 */

// Polyfills
import "core-js/es6/symbol";
import "core-js/es6/promise";
import "core-js/fn/array/from";

// Load Webfont
import FontFaceObserver from "fontfaceobserver";
import localforage from "localforage";

import scroll_to from "frontools/events/scroll";
import Siema from 'siema';
import leaflet from "leaflet";


function load_webfonts () {
	var font1 = new FontFaceObserver( "Raleway", { weight: 400 } );

	Promise.all( [
		font1.load()
	] ).then( () => document.body.classList.add( "-webfont" ) );
}

function configure_slideshow () {

	function markBulletActive() {
		let bullet = this.selector.parentNode.querySelector(`.bullet-js[data-slide-index='${this.currentSlide}']`);
		bullet.classList.add("is-active");
	}

	function onChangeCallback() {
		let allBullets = this.selector.parentNode.querySelectorAll('.bullet-js');
		allBullets.forEach((bullet) => {
			bullet.classList.remove('is-active');
		});
		markBulletActive.call(this);
	}

	function onInitCallback() {
		markBulletActive.call(this);
	}

	const slideshowContainers = document.querySelectorAll(".slideshow-container-js");
	slideshowContainers.forEach((slideshowContainer) => {
		let slideshow = slideshowContainer.querySelector(".slideshow-js");
		const mySiema = new Siema({
			selector: slideshow,
			loop: true,
			onInit: onInitCallback,
			onChange: onChangeCallback
		});
		let duration = 0;
		if (slideshow.dataset.duration && !isNaN(slideshow.dataset.duration)) {
			duration = slideshow.dataset.duration * 1000;
		}
		let sliderInterval = null;
		if (duration > 0) {
			sliderInterval = setInterval(() => {return mySiema.next()}, duration);
		}
		const bullets = slideshowContainer.querySelectorAll(".bullet-js");
		bullets.forEach((bullet) => {
			bullet.addEventListener('click', () => {
				mySiema.goTo(bullet.dataset.slideIndex);

				if (sliderInterval) {
					clearInterval(sliderInterval);
					sliderInterval = setInterval(() => {return mySiema.next()}, duration);
				}
			});
		});
	});
}

function configure_stage () {
	let stage = document.getElementById( "Stage" );

	if(stage) {
		let stageH = stage.offsetHeight;
		let stageContainer = document.getElementById("StageContainer");

		if (stageContainer) {
			var primHeader = document.getElementById('Header');
			var primHeaderH = primHeader.offsetHeight;
			var mode = "onload";

			var mobileImg = stageContainer.dataset['mobile'];
			var desktopImg = stageContainer.dataset['desktop'];
			var defaultImg = stageContainer.dataset['default'];

			function change_stage_img() {
				if (window.innerWidth > 767 && mode != "desktop") {
					if (desktopImg != "") {
						stageContainer.style.backgroundImage = "url(" + desktopImg + ")";
					} else {
						stageContainer.style.backgroundImage = "url(" + defaultImg + ")";
					}
					mode = "desktop";
				}
				if (window.innerWidth <= 767 && mode != "mobile") {
					if (mobileImg != "" && mode != "mobile") {
						stageContainer.style.backgroundImage = "url(" + mobileImg + ")";
					} else {
						stageContainer.style.backgroundImage = "url(" + defaultImg + ")";
					}
					mode = "mobile";
				}
			}

			window.addEventListener("resize", change_stage_img);
			change_stage_img();

			function do_parallax_ev() {
				let scroll = Math.round(window.pageYOffset / window.innerHeight * stageH);
				var minScroll = 0,
					maxScroll = stageH + primHeaderH;

				if (window.innerWidth > 767 && scroll >= minScroll && scroll <= maxScroll) {
					stageContainer.style.transform = `translateY(${scroll / 2}px) translateZ(0)`;
				} else {
					stageContainer.style.transform = `translateY(0) translateZ(0)`;
				}
			}

			if (stage) {
				stage.style.opacity = 1;
				window.addEventListener("scroll", do_parallax_ev);
			}
		}
	}
}

function configure_seminars () {
	console.log("seminars init");
	var seminarDates = document.querySelectorAll('.seminar-date');

    for (var i = 0; i < seminarDates.length; ++i) {

    	//Form submission event
		if(seminarDates[i].querySelector('form')){
			seminarDates[i].querySelector('form').addEventListener("submit", function (event) {
				event.preventDefault();
				if(validateRequiredFields(event.currentTarget)) {
					sendForm(event.currentTarget);
				}
			});
		}

    	//Accordion event
		if(seminarDates[i].querySelector('.registration-button')) {
			seminarDates[i].querySelector('.registration-button').addEventListener("click", function (event) {
				var button = event.currentTarget;
				var registrationForm = button.parentElement.querySelector('.registration-form');
				var formHeight = registrationForm.querySelector('.registration-form-container').offsetHeight;
				if(!button.classList.contains('is-open')) {
					//open
					button.classList.add('is-open');
					registrationForm.style.height = formHeight+'px';
					setTimeout(() => {
						registrationForm.style.height = 'auto';
					}, 500);
				} else {
					//close
					button.classList.remove('is-open');
					registrationForm.style.height = formHeight+'px';
					setTimeout(() => {
						registrationForm.style.height = '0';
					}, 10);
				}
			});
		}
    }


    var sendForm = function(form) {
        var xhr = new XMLHttpRequest();
        var fd = new FormData(form);

        var success = form.parentElement.querySelector('.success');
        var error = form.parentElement.querySelector('.error');
        var loading = form.parentElement.querySelector('.loading');

        form.style.display = 'none';
        loading.style.display = 'block';

        xhr.addEventListener("load", function() {
            if (xhr.status === 200) {
            	error.style.display = 'none';
            	loading.style.display = 'none';
            	success.style.display = 'block';
			} else {
                console.log('Fehler');
            	success.style.display = 'none';
            	loading.style.display = 'none';
            	error.style.display = 'block';
			}
        });

        // Define what happens in case of error
        xhr.addEventListener("error", function() {
            success.style.display = 'none';
            loading.style.display = 'none';
            error.style.display = 'block';
        });

        // Set up our request
        xhr.open('POST', window.mgnlContextPath + '/.seminar-registration/');

        // The data sent is what the user provided in the form
        xhr.send(fd);
	}


    var validateRequiredFields = function(form) {
        let valid = true;

        //Check Text Inputs
        let requiredTextInputs = [ ...form.querySelectorAll( "input[type=text][required]" ) ];
        for ( let requiredTextInput of requiredTextInputs ) {
            if ( requiredTextInput.value == '' ) {
                valid = false;
                if ( !requiredTextInput.parentElement.classList.contains( 'invalid' ) ) {
                    requiredTextInput.parentElement.classList.add( 'invalid' );
                }
            } else {
                if ( requiredTextInput.parentElement.classList.contains( 'invalid' ) ) {
                    requiredTextInput.parentElement.classList.remove( 'invalid' );
                }
            }
        }

        //Check Checkboxes
        let requiredCheckboxes = [ ...form.querySelectorAll( "input[type=checkbox][required]" ) ];
        for ( let requiredCheckbox of requiredCheckboxes ) {
            if ( !requiredCheckbox.checked ) {
                valid = false;
                if ( !requiredCheckbox.parentElement.classList.contains( 'invalid' ) ) {
                    requiredCheckbox.parentElement.classList.add( 'invalid' );
                }
            } else {
                if ( requiredCheckbox.parentElement.classList.contains( 'invalid' ) ) {
                    requiredCheckbox.parentElement.classList.remove( 'invalid' );
                }
            }
        }

        return valid;
    }

}

function initOpenStreetMap() {
	var mapElement = document.querySelector("#map");

	if (mapElement) {
		var lng = 0;
		var lat = 0;

		if (mapElement.hasAttribute("data-lng") && mapElement.hasAttribute("data-lat")) {
			lng = mapElement.getAttribute("data-lng");
			lat = mapElement.getAttribute("data-lat");
		}

		var mymap = leaflet.map('map').setView([lat, lng], 13);
		var marker = leaflet.marker([lat, lng]).addTo(mymap);

		var OpenStreetMap_Mapnik = leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			maxZoom: 19,
			attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
		}).addTo(mymap);
	}
}


function main () {
	initOpenStreetMap();
	setTimeout( load_webfonts, 0 ); // force async
	scroll_to();
	configure_slideshow();
	configure_stage();
    configure_seminars();

	var Burger = document.getElementById("Burger");
	var Cross = document.getElementById("Cross");
	var Backdrop = document.getElementById("mainNav-backdrop");
	var Menu = document.getElementById('Menu');
	var MenuContainer = document.getElementById('MenuContainer');
	var viewEl = document.getElementById('View');
	var htmlEl = document.body.parentNode;


	var switchMenu = function(){
		if (!Burger.classList.contains('open')) {
			Burger.classList.add('open');
			Menu.classList.add('-is-open');
			/*htmlEl.classList.add('-no-scroll');*/
			viewEl.classList.add('-no-scroll');
		}
		else {
			Burger.classList.remove('open');
			Menu.classList.remove('-is-open');
			/*htmlEl.classList.remove('-no-scroll');*/
			viewEl.classList.remove('-no-scroll');
		}
	};

	Burger.addEventListener('click', function(ev) {
		ev.preventDefault();
		ev.stopPropagation();
		switchMenu();
	});

	Cross.addEventListener('click', function(ev) {
		ev.preventDefault();
		ev.stopPropagation();
		switchMenu();
	});

	Backdrop.addEventListener('click', function(ev) {
		ev.preventDefault();
		ev.stopPropagation();
		switchMenu();
	});


	if(document.body.classList.contains('categoryOverview')) {
		var referrerUrl = document.referrer.split('~')[0];
		var currentUrl = document.location.href.split('~')[0];
		var catLinks = [...document.getElementsByClassName('category-selector-link')];

		localforage.getItem(currentUrl, function(err, value) {
			if (value!=null) {
				if (value != 0 && referrerUrl == currentUrl){
					document.body.scrollTop = previousScrollPosition;
				}
			}
		});

		catLinks.forEach(function(catLink) {
			catLink.addEventListener("click", function(e){
				e.preventDefault();
				localforage.setItem(currentUrl, document.body.scrollTop);
				document.location = this.href;
			});
		});
	}

	var mToggle = function(menuToggle){
		var obj = this;
		var thisToggle = menuToggle;
		var menuId = menuToggle.dataset.menu;
		var thisMenu = document.getElementById(menuId);

		obj.triggerClick = function(){
			if(thisToggle.classList.contains('-is-active')) {
				thisToggle.classList.remove("-is-active");
				thisMenu.classList.remove("-is-open");
			} else {
				thisToggle.classList.add("-is-active");
				thisMenu.classList.add("-is-open");
			}
		};

		return obj;
	};

	var mRedirect = function(menuRedirect){
		var thisMenuRedirect = menuRedirect;
		var toggleId = thisMenuRedirect.dataset.toggle;
		var thisToggle = document.getElementById(toggleId);

		var thisMenuToggle = new mToggle(thisToggle);

		thisMenuRedirect.addEventListener("click", function() {
			thisMenuToggle.triggerClick();
		});

		return this;
	};

	var menuRedirects = [ ...document.getElementsByClassName('redirect')];

	menuRedirects.forEach(function(menuRedirect) {
		var thisMenuRedirect = new mRedirect(menuRedirect);
	});

	var subMenus = [ ...document.getElementsByClassName('submenu-col')];

	if(subMenus.length > 0) {

		subMenus.forEach(function(subMenu) {
			var thisSubMenu = subMenu;

			function resizeMenu() {
				var thisH = thisSubMenu.scrollHeight;
				thisSubMenu.style.maxHeight = thisH + 80 + "px";
			}

			resizeMenu();

			window.addEventListener('resize', function(event){
				resizeMenu();
			});
		});
	}

	var scrollTopMarker = document.getElementById('scrolltop-marker');
	var scrollTopArrow = document.getElementById('scrolltop-arrow');

	if(scrollTopArrow){
		scrollTopArrow.addEventListener("click", function() {
			scrollTopMarker.scrollIntoViewport({ speed: 50, marginTop: 0 });
		});
	}

}

main();

